import React from 'react'
import LogoImg from '../../images/logo.svg'
import { FaBars } from 'react-icons/fa'

import {
    Nav,
    LeftContainer,
    RightContainer,
    NavLogo,
    NavImg,
    MobileIcon,
    NavMenu,
    NavItem,
    NavLinks,
    NavBtn,
    NavBtnLink
} from './NavbarElements'

const Navbar = ({ toggle }) => {
    return (
        <Nav>
            <LeftContainer>
                <NavLogo to='/'>
                    <NavImg src={LogoImg} />
                </NavLogo>
                <MobileIcon onClick={toggle}>
                    <FaBars />
                </MobileIcon>
                <NavMenu>
                    <NavItem>
                        <NavLinks to='/search'>Encuentra un profesional</NavLinks>
                    </NavItem>
                    <NavItem>
                        <NavLinks to='/enterprise'>Empresas</NavLinks>
                    </NavItem>
                </NavMenu>
            </LeftContainer>
            <RightContainer>
                <NavMenu>
                    <NavItem>
                        <NavLinks to='/login'>Inicio de sesión</NavLinks>
                    </NavItem>
                    <NavBtn>
                        <NavBtnLink to='/signup'>Registro</NavBtnLink>
                    </NavBtn>
                </NavMenu>
            </RightContainer>
        </Nav>
    )
}

export default Navbar