import React from 'react';
import { FaFacebook, FaInstagram, FaTwitter} from 'react-icons/fa';
import {
  FooterContainer,
  FooterWrap,
  FooterLinksContainer,
  FooterLinksWrapper,
  FooterLinkItems,
  FooterLinkTitle,
  FooterLink,
  WebsiteRightsContainer,
  WebsiteRightsWrap,
  WebsiteRights,
  WebsiteRightsLink,
} from './FooterElements';

const Footer = () => {
  return (
    <FooterContainer>
      <FooterWrap>
        <FooterLinksContainer>
          <FooterLinksWrapper>
            <FooterLinkItems>
              <FooterLinkTitle>Nutrihome</FooterLinkTitle>
              <FooterLink to='/articles'>Artículos</FooterLink>
              <FooterLink to='/forum'>Fórum</FooterLink>
              <FooterLink to='/suggestions'>Sugerencias</FooterLink>
              <FooterLink to='/enterprise'>Empresas</FooterLink>
              <FooterLink to='/testimonials'>Opiniones</FooterLink>
            </FooterLinkItems>
            <FooterLinkItems>
              <FooterLinkTitle>Especialidades</FooterLinkTitle>
              <FooterLink to='/search/generalist'>Generalista</FooterLink>
              <FooterLink to='/search/oncology'>Oncológica</FooterLink>
              <FooterLink to='/search/sports'>Deportiva</FooterLink>
              <FooterLink to='/search/perinatal'>Perinatal</FooterLink>
              <FooterLink to='/search/geriatric'>Geriátrica</FooterLink>
              <FooterLink to='/search/eatingdisorder'>Transtornos Alimentarios</FooterLink>
              <FooterLink to='/search/vegetarian'>Vegetariana</FooterLink>
            </FooterLinkItems>
          </FooterLinksWrapper>
          <FooterLinksWrapper>
            <FooterLinkItems>
              <FooterLinkTitle>Contáctanos</FooterLinkTitle>
              <FooterLink to='/contact'>Contacto</FooterLink>
              <FooterLink to='/support'>Soporte Técnico</FooterLink>
              <FooterLink to='/sponsors'>Sponsors</FooterLink>
              <FooterLink to='/faqs'>FAQs</FooterLink>
            </FooterLinkItems>
            <FooterLinkItems>
              <FooterLinkTitle>Redes Sociales</FooterLinkTitle>
              <FooterLink to='/#' target='_blank' aria-label='Instagram'><FaInstagram /> Instagram</FooterLink>
              <FooterLink to='/#' target='_blank' aria-label='Facebook'><FaFacebook /> Facebook</FooterLink>
              <FooterLink to='/#' target='_blank' aria-label='Twitter'><FaTwitter/> Twitter</FooterLink>
            </FooterLinkItems>
          </FooterLinksWrapper>
        </FooterLinksContainer>
        <WebsiteRightsContainer>
          <WebsiteRightsWrap> 
            <WebsiteRights>
              Nutrihome © {new Date().getFullYear()} All rights reserved.
              <WebsiteRightsLink to='/terms'> Condiciones </WebsiteRightsLink>
              |
              <WebsiteRightsLink to='/privacy'> Privacidad </WebsiteRightsLink>
            </WebsiteRights>
          </WebsiteRightsWrap>
        </WebsiteRightsContainer>
      </FooterWrap>
    </FooterContainer>
  );
}

export default Footer;