import React from 'react';
import LogIn from '../components/Login';

const LoginPage = () => {
    
    return (
        <>
            <LogIn/>
        </>
    )
}

export default LoginPage