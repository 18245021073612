import React, { useEffect } from 'react';
import { DataClient } from '../DataClient';
import {
    PersonalContainer,
    PersonalWrapper,
    Title,
    FormWrap,
    FormContent,
    Form,
    FormInput,
    FormSelectWrap,
    FormSelect,
    FormOption,
    FormLabel,
    OptionsWrapper,
    FormButton
} from './PersonalElements'

const PersonalDashboard = ({setSelected}) => {
    const goals = [
        { value: 'MasaMuscular', label: 'Ganar masa muscular' },
        { value: 'PerderPeso', label: 'Perder peso' },
        { value: 'GanarPeso', label: 'Ganar peso' },
        { value: 'MejorarSalud', label: 'Mejorar mi salud en general' },
        { value: 'MejorarAlimentacion', label: 'Mejorar mis hábitos alimentários' },
        { value: 'Educativo', label: 'Aprender sobre nutrición y alimentación' },
        { value: 'Otros', label: 'Otros' },
    ]

    useEffect(() => {
        setSelected('personaldetails');
    }, [setSelected])


    return (
        <PersonalContainer>
            <PersonalWrapper>
                <Title>Mis datos personales</Title>
                <FormWrap>
                    <FormContent>
                        {DataClient.map((item) => {
                            return (
                            <Form>
                                <FormLabel>Edad: 
                                    <FormInput type='text' placeholder='años' value={`${item.age} años`} />
                                </FormLabel>
                                <FormLabel>Constitución corporal: 
                                    <OptionsWrapper>
                                        <FormOption>
                                            <FormInput type='radio' value='Male' name='constitution' checked={item.gender === 'Male'} /> Masculina
                                        </FormOption>  
                                        <FormOption>
                                            <FormInput type='radio' value='Female' name='constitution' checked={item.gender === 'Female'} /> Femenina
                                        </FormOption> 
                                    </OptionsWrapper>
                                </FormLabel>
                                <FormLabel>Peso: 
                                    <FormInput type='text'  placeholder='kilogramos' value={`${item.weight} kg`} />
                                </FormLabel>
                                <FormLabel>Altura: 
                                    <FormInput type='text' placeholder='metros' value={`${item.height} metros`} />
                                </FormLabel>
                                <FormLabel>IMC: 
                                    <FormInput type='text' placeholder='índice de masa corporal' value={item.imc} disabled />
                                </FormLabel>
                                <FormLabel id='titleMiddleForm'>Salud Nutricional</FormLabel>
                                <FormLabel>Objetivo a cumplir: 
                                    <FormSelectWrap>
                                        <FormSelect value={item.goal}>
                                            <option value='' >Selecciona un objetivo...</option>
                                            {goals.map((goal) => {
                                                return <option>{goal.label}</option>
                                            })}
                                        </FormSelect>
                                    </FormSelectWrap>
                                </FormLabel>
                                <FormLabel>Actividad física: 
                                    <FormInput type='number' min='0' placeholder='días/semana' value={item.exercise} />
                                </FormLabel>
                                <FormLabel>Tipo de dieta: 
                                    <OptionsWrapper>
                                        <FormOption> 
                                            <FormInput type='radio' value='Standard' name='diet' checked={item.diet === 'Standard'} /> Estándard
                                        </FormOption>  
                                        <FormOption>
                                            <FormInput type='radio' value='Vegetarian' name='diet' checked={item.diet === 'Vegetarian'} /> Vegetariana
                                        </FormOption>  
                                        <FormOption>
                                            <FormInput type='radio' value='Vegan' name='diet' checked={item.diet === 'Vegan'} /> Vegana
                                        </FormOption>  
                                        <FormOption>
                                            <FormInput type='radio' value='Other' name='diet' checked={item.diet === 'Other'} /> Otra
                                        </FormOption> 
                                    </OptionsWrapper>
                                </FormLabel>
                                <FormLabel>Alergias e/o intoleráncias: 
                                    <FormInput type='text' value={item.allergies}/>
                                </FormLabel>
                                <FormLabel>Enfermedades:
                                    <OptionsWrapper>
                                        <FormOption>
                                            <FormInput type='checkbox' value='Diabetes' name='diseases' checked={item.diseases.includes('Diabetes')} /> Diabetes
                                        </FormOption> 
                                        <FormOption>
                                            <FormInput type='checkbox' value='Hypertension' name='diseases' checked={item.diseases.includes('Hypertension')} /> Hipertensión
                                        </FormOption>  
                                        <FormOption>
                                            <FormInput type='checkbox' value='Obesity' name='diseases' checked={item.diseases.includes('Obesity')} /> Obesidad
                                        </FormOption>  
                                        <FormOption>
                                            <FormInput type='checkbox' value='Cardiovascular' name='diseases' checked={item.diseases.includes('Cardiovascular')} /> Patologías Cardiovasculares
                                        </FormOption> 
                                        <FormOption>
                                            <FormInput type='checkbox' value='Thyroid' name='diseases' checked={item.diseases.includes('Thyroid')} /> Tiroidea
                                        </FormOption>  
                                        <FormOption>
                                            <FormInput type='checkbox' value='Digestive' name='diseases' checked={item.diseases.includes('Digestive')} /> Patologías Digestivas
                                        </FormOption> 
                                        <FormOption>
                                            <FormInput type='checkbox' value='Other' name='diseases' checked={item.diseases.includes('Other')} /> Otras
                                        </FormOption> 
                                    </OptionsWrapper>
                                </FormLabel>
                                <FormButton type='submit'>Guardar</FormButton>
                                <FormButton id='cancel' type='button'>Cancelar</FormButton>
                            </Form>
                            )
                        })}
                    </FormContent>
                </FormWrap>
            </PersonalWrapper>
        </PersonalContainer>
    )
}

export default PersonalDashboard
