import styled from 'styled-components'

export const InfoContainer = styled.div`
    background: #f9f9f9;
    padding-bottom: 50px;

    @media screen and (max-width: 1000px) {
        padding-top: 80px;
        padding-bottom: 0;
    }

    @media screen and (max-width: 768px) {
        padding: 40px 0;
    }
`;

export const InfoWrapper = styled.div`
    display: grid;
    z-index: 1;
    max-width: 900px;
    margin-right: auto;
    margin-left: auto;
    padding: 10px 24px;
    justify-content: center;

    @media screen and (max-width: 768px) {
        width: auto;
    }
`;

export const InfoRow = styled.div`
    display: grid;
    grid-auto-columns: minmax(auto, 1fr);
    align-items: center;
    grid-template-areas: ${({ imgStart }) => (imgStart ? `'col2 col1'` : `'col1 col2'`)};

    @media screen and (max-width: 768px) {
        grid-template-areas: ${({ imgStart }) => (imgStart ? `'col1' 'col2'` : `'col1 col1' 'col2 col2'`)};
    }
`;

export const Column1 = styled.div`
    margin-bottom: 15px;
    padding: 0 25px;
    grid-area: col1;
    align-self: center;
`;

export const Column2 = styled.div`
    margin-bottom: 15px;
    padding: 0 25px;
    grid-area: col2;
    justify-self: center;
`;

export const TextWrapper = styled.div`
    max-width: 540px;
    padding-top: 0;
`;

export const TitleSection = styled.h1`
    display: ${({ visibility }) => (visibility ? 'block': 'none')};
    color: #ad625c;
    font-size: 2.5rem;
    font-weight: 700;
    text-align: center;
    padding-bottom: 1rem;

    @media screen and (max-width: 768px) {
        font-size: 2rem;
    }
`;

export const SubtitleSection = styled.p`
    display: ${({ visibility }) => (visibility ? 'block': 'none')};
    font-size: 1.5rem;
    line-height: 24px;
    color: #999999;
    text-align: center;
    margin-bottom: 6rem;

    @media screen and (max-width: 768px) {
        font-size: 1.3rem;
    }
`;

export const Heading = styled.h2`
    margin-bottom: 24px;
    font-size: 2rem;
    line-height: 1.1;
    font-weight: 500;
    color: #606c38;

    @media screen and (max-width: 768px) {
        font-size: 1.5rem;
    }
`;

export const Description = styled.p`
    max-width: 440px;
    margin-bottom: 35px;
    font-size: 16px;
    line-height: 24px;
    color: #999999;
`;

export const ImgWrap = styled.div`
    max-width: 250px;
    width: 100%;
    height: 100%;
`;

export const Img = styled.img`
    width: 100%;
    margin: 0 0 10px 0;
    padding-right: 0;
`;