import React, { useState } from 'react';
// import axios from 'axios';
import PSelect from 'pselect.js';
import { DataProfessional } from '../DataProfessional';

import {
    SearchContainer,
    SearchWrapper,
    SelectWrap,
    Title,
    Select,
    ProfessionalInfo,
    ImgProfessional,
    InfoWrap,
    Info,
    BtnWrapper,
    BtnLink
} from './SearchElements';

const Search = ({ openModal, routeChange }) => {  
    const provinces = PSelect.provincesData.map(({ id: value, nm: label }) => ({ value, label }));

    const categories = [
        { value: 'Generalist', label: 'Generalista' },
        { value: 'Oncology', label: 'Oncológica' },
        { value: 'Sports', label: 'Deportiva' },
        { value: 'Perinatal', label: 'Perinatal' },
        { value: 'Geriatric', label: 'Geriátrica' },
        { value: 'EatingDisorder', label: 'Transtornos Alimentarios' },
        { value: 'Vegetarian', label: 'Vegetariana' },
    ]

    const [professionals, setProfessionals] = useState(DataProfessional);

    // Aquest sería el codi per fer la crida a la API
    // axios.get(`${process.env.REACT_APP_API_URL}/getAllProfesionalUsers`)
    //     .then((response) => {
    //         setProfesionals(response.data)
    //     })
    //     .catch((error) => {
    //         console.error(error);
    //     });

    const [province, setProvince] = useState('');
    const [category, setCategory] = useState('');

    const handleChangeProvince = (e) => {
        setProfessionals(DataProfessional);
        setProvince(e.target.value);
    }

    const handleChangeCategory = (e) => {
        setProfessionals(DataProfessional);
        setCategory(e.target.value);
    }

    const searchProfessional = () => {
        let filter = [];
        professionals.map((item) => {
            if(category === '') {
                if(item.location === province) {
                    filter.push(item)
                }
            } else if (province === '') {
                item.categories.map((c) => {
                    if(c === category) {
                        filter.push(item)
                    }
                });
            } else {
                item.categories.map((c) => {
                    if(c === category && item.location === province) {
                        filter.push(item)
                    }
                });
            }
        });
        setProfessionals(filter);
    }

    return (
        <SearchContainer>
            <SearchWrapper>
                <Title>Encuentra tu profesional</Title>
                <SelectWrap>
                    <Select onChange={handleChangeProvince}>
                        <option value='' selected>Selecciona una provincia...</option>
                        {provinces.map((province) => {
                            return <option>{province.label}</option>
                        })}
                    </Select>
                    <Select onChange={handleChangeCategory}>
                        <option value='' selected >Selecciona una categoria...</option>
                        {categories.map((category) => {
                            return <option>{category.label}</option>
                        })}
                    </Select>
                    <BtnWrapper id='search'>
                        <BtnLink onClick={searchProfessional} id='search' >Buscar</BtnLink>
                    </BtnWrapper>
                </SelectWrap>
                {professionals.map((item) => {
                    return (
                        <ProfessionalInfo> 
                            <ImgProfessional src={item.imgProfile} alt='imageProfesional'/>
                            <InfoWrap>
                                <Info id='fullName'>{item.firstName} {item.lastName}</Info>
                                <Info>{item.profession} - {item.location}</Info>    
                                <Info id='categories'>{item.categories.join(', ')}</Info>
                            </InfoWrap>
                            <BtnWrapper>
                                <BtnLink onClick={openModal ? () => openModal({name: item.firstName, lastName: item.lastName}) : routeChange} >+ Añadir Citas</BtnLink>
                            </BtnWrapper>
                        </ProfessionalInfo>
                    );
                })}
            </SearchWrapper>
        </SearchContainer>
        
    )
}

export default Search
