import styled from 'styled-components';

export const ConfContainer = styled.div`
    background: #f9f9f9;
    padding-bottom: 50px;
`;

export const ConfWrapper = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 1000px;
    margin: auto;
    padding: 20px 24px;
`;

export const Title = styled.h1`
    margin-top: 24px;
    font-size: 2rem;
    line-height: 1.1;
    font-weight: 500;
    text-align: left;
    margin-bottom: 25px;
    color: #606c38;
`;

export const FormWrap = styled.div`
    height: 100%;
`;

export const FormContent = styled.div`
    height: 100%;
    max-width: 900px;
    width: 100%;
    cursor: auto;
`;

export const Form = styled.form`
    display: grid;
    grid-auto-columns: minmax(auto, 1fr);
    margin: 0 auto;
    padding: 32px 32px;
    border-radius: 10px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.9);
`;

export const Column1 = styled.div`
    margin-bottom: 15px;
    padding: 0 25px;
    grid-column: 1 / 2;
    grid-row: 1;
`;

export const Column2 = styled.div`
    margin-bottom: 15px;
    padding: 0 25px;
    grid-column: 2 / 2;
    grid-row: 1;
`;

export const FormLabel = styled.label`
    font-size: 15px;
    font-weight: 500;
    color: #606c38;
    margin-bottom: 0.5rem;
    white-space: nowrap;
    margin-right: 5px;
    display: block;
`;

export const FormInput = styled.input`
    margin-left: 5px;
    font-size: 15px;
    color: gray;
    padding: 10px;
    border: none;
    border-bottom: 1px solid gray;
    border-radius: 4px;
    cursor: pointer;
    margin-right: 5px;
    background-color: transparent;

    &#imgProfile {
        border: none;
    }
`;

export const FormSelectWrap = styled.div`
    display: inline;
    margin-left: 5px;
    align-items: center;
`;

export const FormSelect = styled.select`
    height: 32px;
    width: auto;
    padding-left: 5px;
    border: none;
    border-bottom: 1px solid gray;
    border-radius: 4px;
    background-color: transparent;
    color: gray;
    font-size: 15px;
`;

export const ImgProfile = styled.img`
    width: 120px;
    border-radius: 50px;
    padding: 0px 22px;
    margin-left: 10px;
    margin-bottom: 20px;
    border-radius: 50%;
`;

export const FormButton = styled.button`
    border-radius: 4px;
    background: #606c38;
    white-space: nowrap;
    padding: 10px 22px;
    color: #f9f9f9;
    font-size: 16px;
    font-weight: 500;
    outline: none;
    border: none;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    text-decoration: none;
    margin-right: 0.5rem;
    margin-top: 3rem;
    justify-self: flex-start;
    grid-column: 1 / 2;
    grid-row: 2;

    &#cancel {
        background: #ad625c;
    }
    
    &#disabled {
        background: transparent;
        border: 1px solid #ad625c;
        color: #ad625c;
        grid-column: 2 / 2;
        grid-row: 2;

        &:hover {
            background: #ad625c;
            color: #f9f9f9;
        }
    }

    &:hover {
        transition: all 0.2s ease-in-out;
        transform: scale(1.02);
    }
`;