import styled from 'styled-components';

export const ProfessionalsContainer = styled.div`
    background: #f9f9f9;
    padding-bottom: 50px;
`;

export const ProfessionalsWrap = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 1000px;
    margin: auto;
    padding: 20px 24px;
`;

export const Title = styled.h1`
    margin-top: 24px;
    font-size: 2rem;
    line-height: 1.1;
    font-weight: 500;
    text-align: left;
    margin-bottom: 25px;
    color: #606c38;
`;

export const ProfessionalInfo = styled.div`
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
    height: auto;
    box-shadow: 0 6px 10px rgba(0,0,0,.05);
    border: 1px solid #d6dee6;
    border-radius: 4px;
    margin-bottom: 20px;

    @media screen and (max-width: 775px) {
        grid-template-columns: repeat(2,1fr);
        padding-right: 20px;
    };
`;

export const ImgProfessional = styled.img`
    grid-column: 1 / 2;
    grid-row: 1;
    justify-self: center;
    width: 100px;
    margin: 20px;
    border-radius: 50%;
`;

export const InfoWrap = styled.div`
    grid-column: 2 / 2;
    grid-row: 1;
    align-self: center;

    @media screen and (max-width: 775px) {
        text-align: left;
    };
`;

export const Info = styled.span`
    display: block;
    font-size: 18px;
    color: #2e2e2e;
    margin-bottom: 10px;

    &#fullName {
        color: #606c38;
        font-weight: bold;
        font-size: 20px;
    }

    &#categories {
        color: #999999;
        font-size: 16px;
    }
`;

export const BtnWrapper = styled.div`
    grid-column: 3 / 3;
    grid-row: 1;
    margin-top: 50px;
`;

export const BtnLink = styled.button`
    border-radius: 4px;
    background: #606c38;
    padding: 10px 22px;
    color: #f9f9f9;
    font-size: 16px;
    font-weight: 500;
    outline: none;
    border: none;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    text-decoration: none;
    margin-left: 10px;
    
    &:hover {
        transition: all 0.2s ease-in-out;
        background: #ad625c;
        transform: scale(1.02);
    }
`;