import React from 'react'

import {
    SidebarContainer,
    Icon,
    CloseIcon,
    SidebarWrapper,
    SidebarMenu,
    SidebarLink,
    SidebarBtnWrap,
    SidebarBtnLink
} from './SidebarElements'

const Sidebar = ({ isOpen, toggle }) => {
    return (
        <SidebarContainer isOpen={isOpen} onClick={toggle}>
            <Icon onClick={toggle}>
                <CloseIcon />
            </Icon>
            <SidebarWrapper>
                <SidebarMenu>
                    <SidebarLink to='/search'>Encuentra un profesional</SidebarLink>
                    <SidebarLink to='/enterprise'>Empresas</SidebarLink>
                    <SidebarLink to='/signup'>Inicio de sesión</SidebarLink>
                </SidebarMenu>
                <SidebarBtnWrap>
                    <SidebarBtnLink to='/signup'>Registro</SidebarBtnLink>
                </SidebarBtnWrap>
            </SidebarWrapper>
        </SidebarContainer>
    )
}

export default Sidebar