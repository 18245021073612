import styled from 'styled-components'

export const StartContainer = styled.div`
    height: 70vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 20px;
    background: #f9f9f9;

    @media screen and (max-width: 1000px) {
        height: 110vh;
    }

    @media screen and (max-width: 768px) {
        height: 160vh;
    }

    @media screen and (max-width: 480px) {
        height: 160vh;
    }
`;

export const StartWrapper = styled.div`
    max-width: 1000px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: center;
    grid-gap: 16px;
    padding: 0 50px;

    @media screen and (max-width: 1000px) {
        grid-template-columns: 1fr 1fr;
    }

    @media screen and (max-width: 768px) {
        grid-template-columns: 1fr;
        padding: 0 20px;
    }
`;

export const StartCard = styled.div`
    background: #f9f9f9;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    border: 2px solid #bccea5;
    border-radius: 10px;
    height: 250px;
    padding: 30px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.2);
`;

export const StartIcon = styled.img`
    height: 160px;
    width: 160px;
    margin-bottom: 10px;
`;

export const StartH1 = styled.h1`
    font-size: 2.5rem;
    font-weight: 700;
    margin-bottom: 64px;
    color: #ad625c;

    @media screen and (max-width: 480px) {
        font-size: 2rem;
    }
`;

export const StartH2 = styled.h2`
    font-size: 1rem;
    color: #606c38;
    margin-bottom: 10px;
`;

export const StartP = styled.p`
    font-size: 1rem;
    color: #999999;
    text-align: center;
`;