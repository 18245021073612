import React, { useState } from 'react';
import Navbar from '../components/NavbarHome/index';
import Sidebar from '../components/SidebarHome/index';
import HeroSection from '../components/HeroSection/index'
import StartSection from '../components/StartSection/index'
import InfoSection from '../components/InfoSection/index'
import { infoObjOne, infoObjTwo, infoObjThree, infoObjFour } from '../components/InfoSection/Data';

const Home = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen)
  }

  return (
    <>
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <Navbar toggle={toggle}/>
      <HeroSection />
      <StartSection />
      <InfoSection {...infoObjOne}/>
      <InfoSection {...infoObjTwo}/>
      <InfoSection {...infoObjThree}/>
      <InfoSection {...infoObjFour}/>
    </>
  );
};

export default Home;