import React, { useEffect } from 'react';
import { DataClient } from '../DataClient';

import {
    ProfessionalsContainer,
    ProfessionalsWrap,
    Title,
    ProfessionalInfo,
    ImgProfessional,
    InfoWrap,
    Info,
    BtnWrapper,
    BtnLink
} from './ProfesionalElements';

const ProfessionalsDashboard = ( {openModal, setSelected} ) => {
    let professionals = [];
    DataClient.forEach(item => {
        professionals = item.professionals;
    });

    useEffect(() => {
        setSelected('professionals');
    }, [setSelected])

    return (
        <ProfessionalsContainer>
            <ProfessionalsWrap>
            <Title>Mis profesionales</Title>
            {professionals.map((item) => {
                return (
                    <ProfessionalInfo> 
                        <ImgProfessional src={item.imgProfile} />
                        <InfoWrap>
                            <Info id='fullName'>{item.firstName} {item.lastName}</Info>
                            <Info>{item.profession} - {item.location}</Info>    
                            <Info id='categories'>{item.categories.join(', ')}</Info>
                        </InfoWrap>
                        <BtnWrapper>
                            <BtnLink onClick={() => openModal({name: item.firstName, lastName: item.lastName})}>+ Añadir Citas</BtnLink>
                        </BtnWrapper>
                    </ProfessionalInfo>
                );
            })}
            </ProfessionalsWrap>
        </ProfessionalsContainer>
    )
}

export default ProfessionalsDashboard
