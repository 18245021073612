export const DataProfessional = [
    {
        imgProfile: require('../images/profile-12.jpeg'),
        firstName: 'Albert',
        lastName: 'Montana Puig',
        profession: 'Nutricionista',
        location: 'Barcelona',
        categories: [
            'Generalista',
            'Perinatal'
        ]
    },
    {
        imgProfile: require('../images/profile-11.jpeg'),
        firstName: 'Gemma',
        lastName: 'García Tiesto',
        profession: 'Endocrinólogo/a',
        location: 'Tarragona',
        categories: [
            'Generalista',
            'Oncológica'
        ]
    },
    {
        imgProfile: require('../images/profile-10.jpeg'),
        firstName: 'Alba',
        lastName: 'González Pietro',
        profession: 'Nutricionista',
        location: 'Barcelona',
        categories: [
            'Generalista',
            'Transtornos Alimentarios'
        ]
    },
    {
        imgProfile: require('../images/profile-7.jpeg'),
        firstName: 'Francisco',
        lastName: 'Villueva García',
        profession: 'Endocrinólogo/a',
        location: 'Barcelona',
        categories: [
            'Generalista',
            'Geriátrica',
            'Perinatal'
        ]
    },
    {
        imgProfile: require('../images/profile-1.jpeg'),
        firstName: 'Cristina',
        lastName: 'Ramos Deu',
        profession: 'Nutricionista',
        location: 'Lleida',
        categories: [
            'Generalista',
            'Perinatal',
            'Transtornos Alimentarios'
        ]
    },
    {
        imgProfile: require('../images/profile-4.jpeg'),
        firstName: 'Álvaro',
        lastName: 'Ruiz López',
        profession: 'Nutricionista',
        location: 'Barcelona',
        categories: [
            'Generalista',
            'Deportiva'
        ]
    },
    {
        imgProfile: require('../images/profile-9.jpeg'),
        firstName: 'Angela',
        lastName: 'Matamoros Cid',
        profession: 'Nutricionista',
        location: 'Barcelona',
        categories: [
            'Generalista',
            'Deportiva',
            'Vegetariana'
        ]
    }
];