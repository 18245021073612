import React from 'react';
import { FaHome, FaCalendarAlt, FaBookMedical, FaChalkboardTeacher, FaFolder } from 'react-icons/fa';
import { 
    HeaderContainer, 
    HeaderWrapper, 
    HeaderLinksContainer, 
    HeaderLinksWrap, 
    HeaderLink, 
    LinkLabel 
} from './HeaderElements'

const HeaderDashboard = ({ selected }) => {
    return (
        <HeaderContainer>
            <HeaderWrapper>
                <HeaderLinksContainer>
                    <HeaderLinksWrap>
                        <HeaderLink to='/dashboard/clients' isSelected={selected === 'main'} >
                            <FaHome size={25} />
                            <LinkLabel>Panel de control</LinkLabel>
                        </HeaderLink>
                        <HeaderLink to='/dashboard/clients/calendar' isSelected={selected === 'calendar'} >
                            <FaCalendarAlt size={25} />
                            <LinkLabel>Calendario</LinkLabel>
                        </HeaderLink>
                        <HeaderLink to='/dashboard/clients/personaldetails' isSelected={selected === 'personaldetails'} >
                            <FaBookMedical size={25} />
                            <LinkLabel>Datos personales</LinkLabel>
                        </HeaderLink>
                        <HeaderLink to='/dashboard/clients/professionals' isSelected={selected === 'professionals'} >
                            <FaChalkboardTeacher size={25} />
                            <LinkLabel>Profesionales</LinkLabel>
                        </HeaderLink>
                        <HeaderLink to='/dashboard/clients/documents' isSelected={selected === 'documents'} >
                            <FaFolder size={25} />
                            <LinkLabel>Documentos</LinkLabel>
                        </HeaderLink>
                    </HeaderLinksWrap>
                </HeaderLinksContainer>
            </HeaderWrapper>
        </HeaderContainer>
    )
}

export default HeaderDashboard
