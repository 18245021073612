import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { FaEllipsisV, FaEdit, FaTrash } from 'react-icons/fa';

const FaEllipsisIcon = styled(FaEllipsisV)`
    color: #6b6b6b;
    font-size: 15px;
    padding-top: 10px;
`;

const ShowMoreWrapper = styled.div`
    &:hover {
        transform: scale(1.02);
    }
`;

const BtnShowMore = styled(Link)`
    color: #f9f9f9;
    text-decoration: none;
    cursor: pointer;
`;

const DropdownItem = styled.div`
    position: absolute;
    box-shadow: 0 6px 10px rgba(0,0,0,.05);
    border: 1px solid transparent;
    border-radius: 4px;
`;

const DropdownLink = styled(Link)`
    height: 40px;
    padding: 0 1rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    text-decoration: none;
    color: #f9f9f9;
    font-size: 16px;
    transition: 0.2s all ease;


    &#edit {
        background-color: #bccea5;
        border-radius: 4px 4px 0 0;
    }

    &#cancel {
        background-color: #ad625c;
        border-radius: 0 0 4px 4px;
    }
`;

const ShowMore = () => {
    const [more, setMore] = useState(false);

    const showMore = () => setMore(!more);

    return (
        <ShowMoreWrapper onMouseEnter={showMore} onMouseLeave={showMore}>
            <BtnShowMore>
                <FaEllipsisIcon />
            </BtnShowMore>
            {more && (
                <DropdownItem>
                    <DropdownLink id='edit' to='/dashboard/clients'>
                        <FaEdit size={20}/>
                    </DropdownLink>
                    <DropdownLink id='cancel' to='/dashboard/clients'>
                        <FaTrash size={20}/>
                    </DropdownLink>
                </DropdownItem>
            )}
        </ShowMoreWrapper>
    );
};

export default ShowMore;