export const DataClient = [
    {
        imgProfile: require('../images/profile-3.jpeg'),
        firstName: 'Carmen',
        lastName: 'Fernández Zamora',
        password: '123456',
        dni: '123456J',
        location: 'Badalona',
        age: 52,
        gender: 'Female',
        weight: '63',
        height: '1,70',
        imc: '21,79',
        goal: 'Ganar masa muscular',
        exercise: '0',
        diet: 'Standard',
        allergies: 'Lactosa',
        diseases: [
            'Thyroid',
            'Digestive'
        ],
        professionals: [
            {
                imgProfile: require('../images/profile-12.jpeg'),
                firstName: 'Albert',
                lastName: 'Montana Puig',
                profession: 'Nutricionista',
                location: 'Barcelona',
                categories: [
                    'Generalista',
                    'Perinatal'
                ]
            },
            {
                imgProfile: require('../images/profile-11.jpeg'),
                firstName: 'Gemma',
                lastName: 'García Tiesto',
                profession: 'Endocrinólogo/a',
                location: 'Tarragona',
                categories: [
                    'Generalista',
                    'Oncológica'
                ]
            },
        ],
        events: [
            {
                statusEvent: 'Programada',
                imgProfessional: require('../images/profile-12.jpeg'),
                dateEvent: 'martes - 13 de enero de 2022',
                day: 13,
                month: 1,
                year: 2022,
                timeEvent: '19:00'
            },
            {
                statusEvent: 'Completada',
                imgProfessional: require('../images/profile-12.jpeg'),
                dateEvent: 'martes - 21 de diciembre de 2021',
                day: 21,
                month: 12,
                year: 2021,
                timeEvent: '19:30'
            },
            {
                statusEvent: 'Completada',
                imgProfessional: require('../images/profile-11.jpeg'),
                dateEvent: 'martes - 7 de diciembre de 2021',
                day: 7,
                month: 12,
                year: 2021,
                timeEvent: '18:00'
            },
        ]
    }
];