import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import NavbarHome from '../components/NavbarHome/index';
import NavbarDashboard from '../components/NavbarDashboard/index';
import SidebarHome from '../components/SidebarHome/index';
import SidebarDashboard from '../components/SidebarDashboard/index';
import Search from '../components/SearchProfessional/index';
import { Modal } from '../components/Modal';


export const SearchHome = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => {
        setIsOpen(!isOpen)
    }

    const history = useHistory();

    const routeChange = () => {
        let path = '/signup';
        history.push(path);
    }

    return (
        <>
            <SidebarHome isOpen={isOpen} toggle={toggle} />
            <NavbarHome toggle={toggle}/>
            <Search routeChange={routeChange} />
        </>
    );
};

export const SearchDashboard = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => {
        setIsOpen(!isOpen)
    }

    const [showModal, setShowModal] = useState(false);
    const [defaultData, setDefaultData] = useState(null);

    const openModal = (data) => {
        setShowModal(!showModal);
        setDefaultData(data);
    };

    return (
        <>
            <SidebarDashboard isOpen={isOpen} toggle={toggle} />
            <NavbarDashboard toggle={toggle}/>
            <Modal showModal={showModal} setShowModal={setShowModal} defaultData={defaultData} />
            <Search openModal={openModal} />
        </>
    );
};