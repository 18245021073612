import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Container = styled.div`
    min-height: 692px;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    z-index: 0;
    overflow: hidden;
    background: linear-gradient(
        108deg,
        rgb(188, 206, 165, 1) 0%,
        rgb(96, 108, 56, 1) 100%
    );
`;

export const FormWrap = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media screen and (max-width: 400px) {
        height: 80%;
    }
`;

export const FormContent = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    cursor: auto;

    @media screen and (max-width: 480px) {
        padding: 10px;
    }
`;

export const Form = styled.form`
    background: #bccea5;
    max-width: 410px;
    height: auto;
    width: 100%;
    z-index: 1;
    display: grid;
    margin: 0 auto;
    padding: 80px 32px;
    border-radius: 10px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.9);

    @media screen and (max-width: 400px) {
        padding: 32px 32px;
    }
`;

export const FormLogo = styled(Link)`
    display: flex;
    justify-self: center;
    align-items: center;
    cursor: pointer;
`;

export const ImgLogo = styled.img`
    width: 100%;
    margin-bottom: 2rem;
    transition: 0.2s ease-in-out;

    &:hover {
        transform: scale(1.02);
        transition: 0.2s ease-in-out;
    }
`;

export const Checkbox = styled.input.attrs({ type: 'checkbox' })`
    margin-right: 0.5rem;       
`;

export const FormLabel = styled.label`
    font-size: 14px;
    font-weight: 500;
    color: #606c38;
    margin-bottom: 1.5rem;
`;

export const FormInput = styled.input`
    padding: 16px 16px;
    margin-bottom: 32px;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    margin-right: 0.5rem;

    &#professional {
        display: ${({ isChecked }) => (isChecked ? 'block' : 'none')};
    }
`;

export const FormButton = styled.button`
    border-radius: 4px;
    background: #606c38;
    white-space: nowrap;
    padding: 10px 22px;
    color: #f9f9f9;
    font-size: 16px;
    font-weight: 500;
    outline: none;
    border: none;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    text-decoration: none;
    margin-right: 0.5rem;
    
    &:hover {
        transition: all 0.2s ease-in-out;
        background: #ad625c;
        transform: scale(1.02);
    }
`;

export const TextRights = styled.small`
    margin-top: 24px;
    color: #f9f9f9;
    font-size: 12px;
    font-weight: 400;
`;

export const RightsLink = styled(Link)`
    color: #ffff;
    text-decoration: none;

    &:hover {
        color: #ad625c;
    }
`;

export const LoginLink = styled(Link)`
    display: flex;
    justify-content: center;
    margin-top: 10px;
    color: #ad625c;
    font-weight: 500;
    text-decoration: none;
    cursor: pointer;
    transition: all 0.2s ease-in-out;

    &:hover {
        transition: all 0.2s ease-in-out;
        transform: scale(1.02);
    }
`;