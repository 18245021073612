import styled from 'styled-components';
import { FaEllipsisV } from 'react-icons/fa';

export const CalendarDashContainer = styled.div`
    background: #f9f9f9;
    padding-bottom: 50px;
`;

export const CalendarDashWrapper = styled.div`
    display: grid;
    z-index: 1;
    max-width: 1000px;
    margin-right: auto;
    margin-left: auto;
    padding: 10px 24px;
    justify-content: center;
`;

export const CalendarDashRow = styled.div`
    display: grid;
    grid-template-columns: repeat(1,1fr);
    grid-gap: 10px;
    grid-auto-rows: minmax(500px,auto);

    @media screen and (max-width: 775px) {
        grid-template-columns: 1fr;
        grid-template-rows: repeat(2, 1fr);
        text-align: center;
    }
`;

export const Column1 = styled.div`
    width: 15rem;
    margin-top: 60px;
    padding: 0 25px;
    justify-self: center;
    grid-column: 1 / 3;
    grid-row: 1;

    @media screen and (max-width: 984px) {
        width: 90%;
        grid-column: none;
        grid-row: 1 / 2;
    }
`;

export const Column2 = styled.div`
    width: 50rem;
    margin-top: 30px;
    padding: 0 25px;
    justify-self: start;
    grid-column: 3 / 3;
    grid-row: 1;

    @media screen and (max-width: 1125px) {
        width: 90%;
    }

    @media screen and (max-width: 984px) {
        width: 90%;
        grid-column: none;
        grid-row: 2 / 2;
    };
`;

export const InfoWrapper = styled.div`
    box-shadow: 0 6px 10px rgba(0,0,0,.05);
    border: 1px solid #d6dee6;
    border-radius: 4px;
    margin-bottom: 20px;
`;

export const TitleAside = styled.h3`
    color: #ad625c;
    font-weight: 700;
    padding: 1rem;
    background-color: rgba(179, 191, 201, 0.15);
`;

export const InfoAside = styled.div`
    height: auto;
    text-align: center;
`;

export const Info = styled.span`
    display: inline-block;
    justify-content: center;
    font-size: 15px;
    color: #999999;
    margin: 15px 10px;
`;

export const Num = styled.h1`
    text-align: center;
`;

export const Status = styled.span`

`;

export const SessionInfo = styled.div`
    display: grid;
    grid-template-columns: 1fr 2fr 0.1fr;
    height: auto;
    box-shadow: 0 6px 10px rgba(0,0,0,.05);
    border: 1px solid #d6dee6;
    border-radius: 4px;
    margin: 5px;
    padding: 13px 5px;
`;

export const ImgProfessional = styled.img`
    grid-column: 1 / 2;
    grid-row: 1;
    justify-self: center;
    align-self: center;
    width: 50px;
    border-radius: 50%;
`;

export const InfoSession = styled.div`
    grid-column: 2 / 2;
    grid-row: 1;
    align-self: center;
`;

export const StatusSession = styled.span`
    display: block;
    font-size: 14px;
    font-weight: bold;
    color: #606c38;
    margin-bottom: 10px;
`;

export const DateSession = styled.span`
    display: block;
    font-size: 12px;
    color: #999999;
    margin-bottom: 10px;
`;

export const TimeSession = styled.span`
    display: block;
    font-size: 16px;
    font-weight: bold;
    color: #2e2e2e;
    margin-bottom: 10px;
`;

export const FaEllipsisIcon = styled(FaEllipsisV)`
    color: #6b6b6b;
    font-size: 15px;
`;

export const TitleWrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(1,1fr);
`;

export const Title = styled.h1`
    grid-column: 1 / 3;
    grid-row: 1;
    margin-top: 24px;
    font-size: 2rem;
    line-height: 1.1;
    font-weight: 500;
    text-align: left;
    margin-left: 15px;
    margin-bottom: 50px;
    color: #606c38;
`;

export const BtnWrapper = styled.div`
    grid-column: 3 / 3;
    grid-row: 1;
    margin-top: 30px;
`;

export const BtnLink = styled.button`
    border-radius: 4px;
    background: #606c38;
    padding: 10px 22px;
    color: #f9f9f9;
    font-size: 16px;
    font-weight: 500;
    outline: none;
    border: none;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    text-decoration: none;
    margin-left: 10px;
    
    &:hover {
        transition: all 0.2s ease-in-out;
        background: #ad625c;
        transform: scale(1.02);
    }
`;
