import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import Layout from './components/Layout';
import Home from './pages/Home';
import {SearchHome, SearchDashboard } from './pages/SearchProfessional';
import SignUp from './pages/SignUp';
import LogIn from './pages/LogIn';
import { DashboardPage } from './pages/Dashboard';
import Configuration from './pages/Configuration';
import NotFound from './pages/NotFound';

function App() {
  return (
    <BrowserRouter>
      <Layout>
        <Switch>
          <Route path='/' exact component={Home} />
          <Route exact path='/search' component={SearchHome} />
          {/* <Route exact path='/enterprise' component={} /> */}
          <Route path='/signup' exact component={SignUp} />
          <Route path='/login' exact component={LogIn} />
          <Route path='/dashboard/clients/search' exact component={SearchDashboard} />
          <Route path='/dashboard/clients' component={DashboardPage} />
          <Route path='/configuration' exact component={Configuration} />
          {/* <Route exact path='/articles' component={} /> */}
          {/* <Route exact path='/support' component={} /> */}
          {/* <Route exact path='/forum' component={} /> */}
          <Route component={NotFound} />
        </Switch>
      </Layout>
    </BrowserRouter>
  );
}

export default App;