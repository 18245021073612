import React, { useState } from 'react';
import Navbar from '../components/NavbarDashboard/index';
import Sidebar from '../components/SidebarDashboard/index';
import Configuration from '../components/ConfigurationUser/index';

const ConfigurationPage = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => {
        setIsOpen(!isOpen)
    }

    return (
        <>
            <Sidebar isOpen={isOpen} toggle={toggle} />
            <Navbar toggle={toggle}/>
            <Configuration />
        </>
    );
};

export default ConfigurationPage;