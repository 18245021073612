import React, { useState } from 'react';
import SignUp from '../components/Signup';

const SignupPage = () => {
    const [isChecked, setIsChecked] = useState(false);

    const toggle = () => {
        setIsChecked(!isChecked)
    }

    return (
        <SignUp isChecked={isChecked} toggle={toggle}/>
    )
}

export default SignupPage
