import React from 'react';
import { DataClient } from '../DataClient';
import PSelect from 'pselect.js';
import {
    ConfContainer,
    ConfWrapper,
    Title,
    FormWrap,
    FormContent,
    Form,
    Column1,
    Column2,
    FormLabel,
    FormInput,
    FormSelectWrap,
    FormSelect,
    ImgProfile,
    FormButton
} from './ConfigurationElements';

const ConfigurationUser = () => {
    const municipes = PSelect.municipesData.map(({ id: value, nm: label }) => ({ value, label }));

    return (
        <ConfContainer>
            <ConfWrapper>
            <Title>Configuración</Title>
                <FormWrap>
                    <FormContent>
                        {DataClient.map(item => {
                            return (
                            <Form>
                                <Column1>
                                    <FormLabel>Nombre: 
                                        <FormInput type='text' value={item.firstName} />
                                    </FormLabel>
                                    <FormLabel>Apellidos: 
                                        <FormInput type='text' value={item.lastName} />
                                    </FormLabel>
                                    <FormLabel>Nueva contraseña: 
                                        <FormInput type='password' />
                                    </FormLabel>
                                    <FormLabel>Repetir nueva contraseña: 
                                        <FormInput type='password' />
                                    </FormLabel>
                                    <FormLabel>DNI/NIE: 
                                        <FormInput type='text' value={item.dni} />
                                    </FormLabel>
                                    <FormLabel>Localidad: 
                                        <FormSelectWrap>
                                            <FormSelect value={item.location}>
                                                <option value="" disabled selected>Selecciona una localidad...</option>
                                                {municipes.map((municipe) => {
                                                    return <option>{municipe.label}</option>
                                                })}
                                            </FormSelect>
                                        </FormSelectWrap>
                                    </FormLabel>
                                </Column1>
                                <Column2>
                                    <ImgProfile src={item.imgProfile} />
                                    <FormInput type='file' id='imgProfile' />
                                </Column2>
                                <div>
                                    <FormButton type='submit'>Guardar</FormButton>
                                    <FormButton id='cancel' type='button'>Cancelar</FormButton>
                                </div>
                                <FormButton id='disabled' type='button'>Desactivar mi cuenta</FormButton>
                            </Form>
                            )
                        })}
                    </FormContent>
                </FormWrap>
            </ConfWrapper>
        </ConfContainer>
    )
}

export default ConfigurationUser
