import React from 'react'

import {
    SidebarContainer,
    Icon,
    CloseIcon,
    SidebarWrapper,
    SidebarMenu,
    Line,
    SidebarLink,
    SidebarBtnWrap,
    SidebarBtnLink
} from './SidebarElements'

const SidebarDashboard = ({ isOpen, toggle }) => {
    return (
        <SidebarContainer isOpen={isOpen} onClick={toggle}>
            <Icon onClick={toggle}>
                <CloseIcon />
            </Icon>
            <SidebarWrapper>
                <SidebarMenu>
                    <SidebarLink to='/dashboard/clients'>Panel de control</SidebarLink>
                    <SidebarLink to='/dashboard/clients/calender'>Citas</SidebarLink>
                    <SidebarLink to='/messages'>Mensajes</SidebarLink>
                    <SidebarLink to='/notifications'>Notificaciones</SidebarLink>
                    <SidebarLink to='/dashboard/clients/search'>Encuentra un professional</SidebarLink>
                </SidebarMenu>
                <Line/>
                <SidebarMenu>
                    <SidebarLink to='/enterprise'>Empresas</SidebarLink>
                    <SidebarLink to='/signup'>Regístrate como profesional</SidebarLink>
                    <SidebarLink to='/articles'>Artículos</SidebarLink>
                    <SidebarLink to='/forum'>Fórum</SidebarLink>
                    <SidebarLink to='/suggestions'>Sugerencias</SidebarLink>
                </SidebarMenu>
                <Line/>
                <SidebarMenu>
                    <SidebarLink to='/configuration'>Configuración</SidebarLink>
                    <SidebarLink to='/support'>Asistencia</SidebarLink>
                    <SidebarBtnWrap>
                        <SidebarBtnLink to='/logout'>Cerrar sesión</SidebarBtnLink>
                    </SidebarBtnWrap>
                </SidebarMenu>
            </SidebarWrapper>
        </SidebarContainer>
    )
}

export default SidebarDashboard