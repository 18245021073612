import React from 'react'

import {
    InfoContainer,
    InfoWrapper,
    TitleSection,
    SubtitleSection,
    InfoRow,
    Column1,
    Column2,
    TextWrapper,
    Heading,
    Description,
    ImgWrap,
    Img
} from './InfoElements'

const InfoSection = ({ lightBg, visibility, titleText, subtitleText, imgStart, lightText, headLine, darkText, description, img, alt }) => {
    return (
        <>
            <InfoContainer lightBg={lightBg}>
                <TitleSection visibility={visibility}>{titleText}</TitleSection>
                <SubtitleSection visibility={visibility}>{subtitleText}</SubtitleSection>
                <InfoWrapper>
                    <InfoRow imgStart={imgStart}>
                        <Column1>
                            <TextWrapper>
                                <Heading lightText={lightText}>{headLine}</Heading>
                                <Description darkText={darkText}>{description}</Description>
                            </TextWrapper>
                        </Column1>
                        <Column2>
                            <ImgWrap>
                                <Img src={img} alt={alt} />
                            </ImgWrap>
                        </Column2>
                    </InfoRow>
                </InfoWrapper>
            </InfoContainer>
        </>
    )
}

export default InfoSection
