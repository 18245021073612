import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const NavItem = styled.li`
    height: 80px;

    &:hover {
        transform: scale(1.02);
        border-bottom: 3px solid #ad625c;
    }
`;

const NavLinks = styled(Link)`
    color: #f9f9f9;
    font-weight: 500;
    display: flex;
    align-items: flex-end;
    text-decoration: none;
    padding: 0 1rem;
    height: 80%;
    cursor: pointer;
`;

const NavLabel = styled.span`
    margin-left: 10px;
    margin-right: 5px;
`;

const DropdownItem = styled.div`
    margin-top: 1.2rem;
    background-color: #f9f9f9;
    box-shadow: 0 6px 10px rgba(0,0,0,.05);
    border: 1px solid transparent;
    border-radius: 4px;
`;

const DropdownLink = styled(Link)`
    height: 40px;
    padding-left: 1rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    text-decoration: none;
    color: #606c38;
    font-size: 16px;
    transition: 0.2s all ease;

    &:hover {
        color: #f9f9f9;
        background: #bccea5;
        cursor: pointer;
        transition: 0.2s all ease;
    }
`;

const SubMenu = ({ item }) => {
    const [subnav, setSubnav] = useState(false);

    const showSubnav = () => setSubnav(!subnav);

    return (
        <>
            <NavItem onMouseEnter={item.subNav && showSubnav} onMouseLeave={item.subNav && showSubnav}>
                <NavLinks to={item.path}>
                    <div>
                        <NavLabel>{item.title}</NavLabel>
                    </div>
                    <div>
                        {item.subNav && subnav ? item.iconOpened : item.subNav ? item.iconClosed : null}
                    </div>
                </NavLinks>
                <DropdownItem>
                    {subnav && item.subNav.map((item, index) => {
                        return (
                            <DropdownLink to={item.path} key={index}>
                                {item.icon} 
                                <NavLabel>{item.title}</NavLabel>
                            </DropdownLink>
                        );
                    })}
                </DropdownItem>
            </NavItem>
        </>
    );
};

export default SubMenu;