import React, { useState } from 'react';
import axios from 'axios';
import LogoImg from '../../images/logo.svg';

import {
    Container,
    FormWrap,
    FormContent,
    Form,
    FormLogo,
    ImgLogo,
    FormLabel,
    FormInput,
    Checkbox,
    FormButton,
    TextRights,
    RightsLink,
    LoginLink
} from './SignupElements';

const Signup = ({ isChecked, toggle }) => {
    const [values, setValues] = useState({
        firstName: '',
        lastName: '',
        username: '',
        password: '',
        collegeNumber: ''
    });

    const handleSubmit = (e) => {
        e.preventDefault();

        const { firstName, lastName, username, password, collegeNumber } = values;
        const client = {firstName, lastName, username, password};
        const profesional = {firstName, lastName, username, password, collegeNumber};

        if(values.collegeNumber !== '') {
            axios.post(`${process.env.REACT_APP_API_URL}/auth/signup/profesionals`, profesional)
            .then(response => {
                const status = response.status;
                if (status === 200) {
                    window.location = '/dashboard/profesionals'
                }
            })
            .catch(error => {
                console.error(error);
            });
        }
        
        axios.post(`${process.env.REACT_APP_API_URL}/auth/signup/clients/`, client)
            .then(response => {
                const status = response.status;
                if (status === 200) {
                    window.location = '/dashboard/clients'
                }
            })
            .catch(error => {
                console.error(error);
            });
    };

    const handleChange = (e) => {
        setValues({ ...values, [e.target.name]: e.target.value });
    };


    return (
        <Container>
            <FormWrap>
                <FormContent>
                    <Form onSubmit={handleSubmit}>
                        <FormLogo to='/'>
                            <ImgLogo src={LogoImg} />
                        </FormLogo>
                        <FormInput type='text' name='firstName' placeholder='Nombre' value={values.firstName} onChange={handleChange} required />
                        <FormInput type='text' name='lastName' placeholder='Apellidos' value={values.lastName} onChange={handleChange} required />
                        <FormInput type='email' name='username' placeholder='Correo electrónico' value={values.username} onChange={handleChange} required />
                        <FormInput type='password' name='password' placeholder='Contraseña' value={values.password} onChange={handleChange} required />
                        <FormInput id='professional' name='numCol' type='text' placeholder='Número colegiado' value={values.numCol} onChange={handleChange} isChecked={isChecked} />
                        <FormLabel>
                            <Checkbox isChecked={isChecked} onClick={toggle}/>
                            Soy profesional colegiado y quiero trabajar con Nutrihome!
                        </FormLabel>
                        <FormButton type='submit'>Registro</FormButton>
                        <TextRights>Al registrarte, aceptas nuestras <RightsLink to='/terms'>Condiciones de uso</RightsLink> y nuestra <RightsLink to='/privacy'>Política de privacidad</RightsLink>.</TextRights>
                        <LoginLink to='/login'>Ya tengo una cuenta</LoginLink>
                    </Form>
                </FormContent>
            </FormWrap>
        </Container>
    )
}

export default Signup
