import React from 'react'
import Icon1 from '../../images/svg-5.svg'
import Icon2 from '../../images/svg-3.svg'
import Icon3 from '../../images/svg-6.svg'
import {
    StartContainer,
    StartH1,
    StartWrapper,
    StartCard,
    StartH2,
    StartIcon,
    StartP
} from './StartElements'

const StartSection = () => {
    return (
        <StartContainer>
            <StartH1>Así es como funciona</StartH1>
            <StartWrapper>
                <StartCard>
                    <StartH2>Encuentra tu professional</StartH2>
                    <StartIcon src={Icon1} />
                    <StartP>Elige entre más de 10000 profesionales en el ámbito de la nutrición</StartP>
                </StartCard>
                <StartCard>
                    <StartH2>Reserva una cita</StartH2>
                    <StartIcon src={Icon2} />
                    <StartP>Selecciona un horario para tu cita y añádelo a tu calendario</StartP>
                </StartCard>
                <StartCard>
                    <StartH2>Empieza a cuidarte</StartH2>
                    <StartIcon src={Icon3} />
                    <StartP>Aquí empieza tu cambio!</StartP>
                </StartCard>
            </StartWrapper>
        </StartContainer>
    )
}

export default StartSection
