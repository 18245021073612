import React, { useRef } from 'react';
import styled from 'styled-components';
import { MdClose } from 'react-icons/md';

const Background = styled.div`
    width: 100%;
    height: 100%;
    background: rgba(249, 249, 249, 0.8);
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const ModalWrapper = styled.div`
    width: auto;
    height: auto;
    box-shadow: 0 5px 16px rgba(0, 0, 0, 0.2);
    background: #f9f9f9;
    position: relative;
    z-index: 10;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const ModalContent = styled.div`
`;

const CloseModalButton = styled(MdClose)`
    cursor: pointer;
    position: absolute;
    top: 20px;
    right: 20px;
    width: 32px;
    height: 32px;
    padding: 0;
    z-index: 10;
`;

const Title = styled.h1`
    margin-top: 24px;
    line-height: 1.1;
    font-weight: 500;
    text-align: center;
    margin-bottom: 25px;
    color: #606c38;
`;

const Form = styled.form`
    margin: 0 auto;
    padding: 32px 32px;
    font-size: 18px;
`;

const FormLabel = styled.label`
    font-weight: 500;
    color: #606c38;
    margin-bottom: 0.5rem;
    white-space: nowrap;
    margin-right: 5px;
    display: block;
`;

const FormInput = styled.input`
    margin-left: 5px;
    padding: 10px;
    border: none;
    border-bottom: 1px solid gray;
    border-radius: 4px;
    cursor: pointer;
    margin-right: 5px;
    background-color: transparent;
    font-size: 18px;
`;

const FormSelectWrap = styled.div`
    display: inline;
    margin-left: 5px;
    align-items: center;
`;

const FormSelect = styled.select`
    height: 32px;
    width: auto;
    padding-left: 5px;
    border: none;
    border-bottom: 1px solid gray;
    border-radius: 4px;
    background-color: transparent;
    color: gray;
    font-size: 18px;
`;

const FormButton = styled.button`
    border-radius: 4px;
    background: #606c38;
    padding: 10px 22px;
    color: #f9f9f9;
    font-size: 16px;
    font-weight: 500;
    outline: none;
    border: none;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    text-decoration: none;
    margin: 2rem 10rem;
    justify-self: center;
    
    &:hover {
        transition: all 0.2s ease-in-out;
        transform: scale(1.02);
    }
`;

export const Modal = ({ showModal, setShowModal, defaultData }) => {
    const modalRef = useRef();

    const closeModal = e => {
        if (modalRef.current === e.target) {
        setShowModal(false);
        }
    };

    const time = [
        { value: 'free', label: '15min (prueba gratuïta)' },
        { value: '30', label: '30min' },
        { value: '45', label: '45min' },
        { value: '60', label: '60min' }
    ]

    return (
        <>
        {showModal ? (
            <Background onClick={closeModal} ref={modalRef}>
                <ModalWrapper showModal={showModal}>
                    <ModalContent>
                        <Form>
                            <Title>Añadir cita nueva</Title>
                            <FormLabel>Dia y hora: 
                                <FormInput type='date' />
                                <FormInput type='time' />
                            </FormLabel>
                            <FormLabel>Tiempo de visita: 
                                <FormSelectWrap>
                                    <FormSelect>
                                        {time.map((t) => {
                                            return <option>{t.label}</option>
                                        })}
                                    </FormSelect>
                                </FormSelectWrap>
                            </FormLabel>
                            <FormLabel>Profesional: 
                                <FormInput type='text' value={`${defaultData.name} ${defaultData.lastName}`} />
                            </FormLabel>
                            <FormButton type='submit'>Añadir</FormButton>
                        </Form>
                    </ModalContent>
                    <CloseModalButton
                        aria-label='Close modal'
                        onClick={() => setShowModal(prev => !prev)}
                    />
                </ModalWrapper>
            </Background>
        ) : null}
        </>
    );
};