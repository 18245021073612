import React, { useEffect } from 'react'

const DocumentsDashboard = ({ setSelected }) => {

    useEffect(() => {
        setSelected('documents');
    }, [])

    return (
        <div>
            
        </div>
    )
}

export default DocumentsDashboard
