import styled from 'styled-components';

export const PersonalContainer = styled.div`
    background: #f9f9f9;
    padding-bottom: 50px;
`;

export const PersonalWrapper = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 1000px;
    margin: auto;
    padding: 20px 24px;
`;

export const Title = styled.h1`
    margin-top: 24px;
    font-size: 2rem;
    line-height: 1.1;
    font-weight: 500;
    text-align: left;
    margin-bottom: 25px;
    color: #606c38;
`;

export const FormWrap = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
`;

export const FormContent = styled.div`
    height: 100%;
    max-width: 800px;
    width: 100%;
    cursor: auto;
`;

export const Form = styled.form`
    margin: 0 auto;
    padding: 32px 32px;
    border-radius: 10px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.9);
`;

export const FormLabel = styled.label`
    font-size: 15px;
    font-weight: 500;
    color: #606c38;
    margin-bottom: 0.5rem;
    white-space: nowrap;
    margin-right: 5px;
    display: block;

    &#titleMiddleForm {
        border-top: 1px solid gray;
        font-size: 20px;
        padding-top: 15px;
        margin-bottom: 15px;
        margin-top: 60px;
        font-weight: 600;
        color: #ad625c;
    }
`;

export const OptionsWrapper = styled.div`
    display: block;
    margin-top: 15px;
`;

export const FormInput = styled.input`
    font-size: 15px;
    margin-left: 5px;
    padding: 10px;
    border: none;
    border-bottom: 1px solid gray;
    border-radius: 4px;
    cursor: pointer;
    margin-right: 5px;
    color: gray;
    background-color: transparent;
`;

export const FormSelectWrap = styled.div`
    display: inline;
    margin-left: 5px;
    align-items: center;
`;

export const FormSelect = styled.select`
    font-size: 15px;
    height: 32px;
    width: auto;
    padding-left: 5px;
    border: none;
    border-bottom: 1px solid gray;
    border-radius: 4px;
    background-color: transparent;
    color: gray;
`;

export const FormOption = styled.span`
    color: gray;
    font-size: 14px;
    font-weight: 400;
    display: flex;
    white-space: nowrap;
    padding: 5px 0;
`;

export const FormButton = styled.button`
    border-radius: 4px;
    background: #606c38;
    white-space: nowrap;
    padding: 10px 22px;
    color: #f9f9f9;
    font-size: 16px;
    font-weight: 500;
    outline: none;
    border: none;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    text-decoration: none;
    margin-right: 0.5rem;
    margin-top: 3rem;
    justify-self: flex-end;

    &#cancel {
        background: #ad625c;
    }
    
    &:hover {
        transition: all 0.2s ease-in-out;
        transform: scale(1.02);
    }
`;