import React from 'react';
import { FaAngleDown, FaAngleUp, FaNewspaper, FaLightbulb, FaBullhorn, FaBriefcase, FaHandsHelping } from 'react-icons/fa'

export const DataLeft = [
    {
        title: 'Encuentra un profesional',
        path: '/dashboard/clients/search',
    },
    {
        title: 'Comunidad',
        path: '/dashboard/clients',
        iconClosed: <FaAngleDown />,
        iconOpened: <FaAngleUp />,

        subNav: [
        {
            title: 'Artículos',
            icon: <FaNewspaper />,
            path: '/articles'
        },
        {
            title: 'Fórum',
            icon: <FaBullhorn />,
            path: '/forum'
        },
        {
            title: 'Sugerencias',
            icon: <FaLightbulb />,
            path: '/suggestions'
        }
        ]
    },
    {
        title: 'Más',
        path: '/dashboard/clients',
        iconClosed: <FaAngleDown />,
        iconOpened: <FaAngleUp />,

        subNav: [
        {
            title: 'Empresas',
            icon: <FaBriefcase />,
            path: '/enterprise'
        },
        {
            title: 'Regístrate como profesional',
            icon: <FaHandsHelping />,
            path: '/signup'
        }
        ]
    }
];