import React, { useState } from 'react';
import axios from 'axios';
import LogoImg from '../../images/logo.svg';

import {
    Container,
    FormWrap,
    FormContent,
    Form,
    FormLogo,
    ImgLogo,
    FormInput,
    FormButton,
    ResetPassword
} from './LoginElements';

const Login = () => {
    const [values, setValues] = useState({
        username: '',
        password: ''
    });

    const handleLogin = (e) => {
        e.preventDefault();

        const { username, password } = values;
        const user = {username, password};

        axios.post(`${process.env.REACT_APP_API_URL}/auth/login`, user)
            .then(response => {
                const status = response.status;
                    if (status === 200) {
                        window.location = '/dashboard/clients'
                    }
                //La resposta retorna un id d'usuari per poder carregar el perfil d'usuari
                // axios.get(`${process.env.REACT_APP_API_URL}/${response.data[0].userId}`)
                //     .then(response => {
                //         const status = response.status;
                //         if (status === 200) {
                //             window.location = '/dashboard/clients'
                //         }
                //     })
            })
            .catch(error => {
                console.error(error);
            });
    };

    const handleChange = (e) => {
        setValues({ ...values, [e.target.name]: e.target.value });
    };

    return (
        <Container>
            <FormWrap>
                <FormContent>
                    <Form onSubmit={handleLogin}>
                        <FormLogo to='/'>
                            <ImgLogo src={LogoImg} />
                        </FormLogo>
                        <FormInput type='email' name='username' placeholder='Correo electrónico' value={values.username} onChange={handleChange} required />
                        <FormInput type='password' name='password' placeholder='Contraseña' value={values.password} onChange={handleChange} required />
                        <FormButton type='submit'>Iniciar Sesión</FormButton>
                        <ResetPassword to='/'>¿Has olvidado tu contraseña?</ResetPassword>
                    </Form>
                </FormContent>
            </FormWrap>
        </Container>
    )
}

export default Login
