import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const FooterContainer = styled.footer`
    background-color: #bccea5;
`;

export const FooterWrap = styled.div`
    padding: 24px 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
`;

export const FooterLinksContainer = styled.div`
    display: flex;
    justify-content: center;   

    @media screen and (max-width: 875px) {
        padding-top: 32px;
    }
`;

export const FooterLinksWrapper = styled.div`
    display: flex;

    @media screen and (max-width: 875px) {
        flex-direction: column;
    }
`;

export const FooterLinkItems = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 1rem 6rem 1rem 1rem;
    text-align: left;
    width: auto;
    box-sizing: border-box;
    color: #606c38;

    @media screen and (max-width: 420px) {
        margin: 0;
        padding: 10px;
        width: 100%;
    }
`;

export const FooterLinkTitle = styled.h1`
    font-size: 16px;
    margin-bottom: 16px;
`;

export const FooterLink = styled(Link)`
    color: #ffff;
    text-decoration: none;
    margin-bottom: 0.5rem;
    font-size: 14px;

    &:hover {
        transform: scale(1.02);
        color: #ad625c;
    }
`;

export const WebsiteRightsContainer = styled.section`
    width: 100%;
    align-content: center;
    border-top: 1px solid #606c38;
`;

export const WebsiteRightsWrap = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 40px auto 0 auto;

    @media screen and (max-width: 875px) {
        flex-direction: column;
    }
`;

export const WebsiteRights = styled.small`
    color: #606c38;
    margin-bottom: 16px;
`;

export const WebsiteRightsLink = styled(Link)`
    color: #ffff;
    text-decoration: none;

    &:hover {
        color: #ad625c;
    }
`;