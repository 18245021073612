import React, { useState } from 'react';
import HomeImg from '../../images/home_bg.svg';

import {
    HeroContainer,
    HeroBg,
    ImgBg,
    HeroContent,
    HeroH1,
    HeroP,
    HeroBtnWrapper,
    HeroBtnLink,
    ArrowForward,
    ArrowRight,
} from './HeroElements';

const HeroSection = () => {
    const [hover, setHover] = useState(false);

    const onHover = () => {
        setHover(!hover)
    }

    return (
        <HeroContainer>
            <HeroBg>
                <ImgBg src={HomeImg}></ImgBg>
            </HeroBg>
            <HeroContent>
                <HeroH1>Tu nutricionista en casa</HeroH1>
                <HeroP>
                    Cuídate sin moverte de casa, <span>CUÁNDO</span>, <span>DÓNDE</span> y con <span>QUIÉN</span> tú quieras
                </HeroP>
                <HeroBtnWrapper>
                    <HeroBtnLink to='/search' onMouseEnter={onHover} onMouseLeave={onHover}>
                        Probar cita gratis {hover ? <ArrowForward /> :  <ArrowRight />}
                    </HeroBtnLink>
                </HeroBtnWrapper>
            </HeroContent>
        </HeroContainer>
    )
}

export default HeroSection
