import React, { useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import Navbar from '../components/NavbarDashboard/index';
import Sidebar from '../components/SidebarDashboard/index';
import DashboardHeader from '../components/HeaderDashboard/index';
import MainDashboard from '../components/MainDashboard';
import CalendarDashboard from '../components/CalendarDashboard/index';
import PersonalDashboard from '../components/PersonalDashboard/index';
import ProfessionalsDashboard from '../components/ProfessionalsDashboard/index';
import DocumentsDashboard from '../components/DocumentsDashboard/index';
import { Modal } from '../components/Modal';


export const DashboardPage = ({match}) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => {
        setIsOpen(!isOpen)
    }

    const [showModal, setShowModal] = useState(false);
    const [defaultData, setDefaultData] = useState(null);

    const openModal = (data) => {
        setShowModal(!showModal);
        setDefaultData(data);
    };

    const [selected, setSelected] = useState(false);

    return (
        <>
            <Sidebar isOpen={isOpen} toggle={toggle} />
            <Navbar toggle={toggle}/>
            <Modal showModal={showModal} setShowModal={setShowModal} defaultData={defaultData} />
            <DashboardHeader selected={selected} />
            <Switch>
                <Route path={match.path} exact component={() => <MainDashboard openModal={openModal} setSelected={setSelected} /> } />
                <Route path={`${match.path}/calendar`} component={() => <CalendarDashboard openModal={openModal} setSelected={setSelected} /> } />
                <Route path={`${match.path}/personaldetails`} component={() => <PersonalDashboard openModal={openModal} setSelected={setSelected} /> } />
                <Route path={`${match.path}/professionals`} component={() => <ProfessionalsDashboard openModal={openModal} setSelected={setSelected} /> } />
                <Route path={`${match.path}/documents`} component={() => <DocumentsDashboard openModal={openModal} setSelected={setSelected} /> } />
            </Switch>
        </>
    );
};