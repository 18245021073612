import React, { useState, useEffect } from 'react';
import moment from 'moment';
import 'moment/locale/es';
import { DataClient } from '../DataClient';
import Calendar from '../CalendarElement';
import ShowMore from '../ShowMoreElement';

import {
    CalendarDashContainer,
    CalendarDashWrapper,
    CalendarDashRow,
    Column1,
    InfoWrapper,
    TitleAside,
    InfoAside,
    Info,
    SessionInfo,
    ImgProfessional,
    InfoSession,
    StatusSession,
    TimeSession,
    DateSession,
    Num,
    Status,
    Column2,
    TitleWrapper,
    Title,
    BtnWrapper,
    BtnLink
} from './CalendarElements'

const CalendarDashboard = ( {openModal, setSelected} ) => {
    let events = [];
    DataClient.map(item => {
        events = item.events;
    })

    const [completed, setCompleted] = useState(0);
    const [reserved, setReserved] = useState(0);
    const [date, setDate] = useState(new Date())
    
    useEffect(() => {
        let countCompleted = completed;
        let countReserved = reserved;
        events.map(e => {
            e.statusEvent === 'Completada' && countCompleted++;
            e.statusEvent === 'Programada' && countReserved++;    
        })
        setCompleted(countCompleted);
        setReserved(countReserved);
        setSelected('calendar');
    }, [setSelected])

    return (
        <CalendarDashContainer>
            <CalendarDashWrapper>
                <CalendarDashRow>
                    <Column1>
                        <InfoWrapper>
                            <TitleAside>Resumen de las citas</TitleAside>
                            <InfoAside>
                                <Info>
                                    <Num>{completed}</Num>
                                    <Status>Completadas</Status>
                                </Info>
                                <Info>
                                    <Num>{reserved}</Num>
                                    <Status>Programadas</Status>
                                </Info>
                            </InfoAside>
                        </InfoWrapper>
                        <InfoWrapper>
                            <TitleAside>Detalles de la cita</TitleAside>
                            <InfoAside>
                            {events.map(e => {
                                return(
                                e.dateEvent.includes(moment(date).format('LL')) &&
                                <SessionInfo> 
                                    <ImgProfessional src={e.imgProfessional} />
                                    <InfoSession>
                                        <StatusSession>{e.statusEvent}</StatusSession>
                                        <TimeSession>{e.timeEvent}</TimeSession>    
                                        <DateSession>{e.dateEvent}</DateSession>
                                    </InfoSession>
                                    <ShowMore />
                                </SessionInfo>
                                )
                            })}
                            </InfoAside>
                        </InfoWrapper>
                    </Column1>
                    <Column2>
                        <TitleWrapper>
                            <Title>Mi calendario de citas</Title>
                            <BtnWrapper>
                                <BtnLink onClick={() => openModal({name: '', lastName: ''})}>+ Añadir Citas</BtnLink>
                            </BtnWrapper>
                        </TitleWrapper>
                        <Calendar setDate={setDate} date={date} />
                    </Column2>
                </CalendarDashRow>
            </CalendarDashWrapper>
        </CalendarDashContainer>
    )
}

export default CalendarDashboard