export const infoObjOne = {
    visibility: true,
    titleText: '¿Por qué me irá mejor con Nutrihome?',
    subtitleText: 'Mejorarás en salud de forma rápida, eficiente y económica',
    headLine: 'Profesionales colegiados',
    description: 'Todos nuestros profesionales son expertos en el ámbito de la nutrición, titulados y colegiados por un organismo estatal además de verificados por nuestro equipo.',
    imgStart: false,
    img: require('../../images/svg-1.svg'),
    alt: 'Verification'
}

export const infoObjTwo = {
    headLine: 'Citas asequibles',
    description: 'Con sesiones desde 9,99 €, Nutrihome ofrece un servicio de salud a distancia que se ajusta a cualquier bolsillo.',
    imgStart: true,
    img: require('../../images/svg-2.svg'),
    alt: 'Wallet'
}

export const infoObjThree = {
    headLine: 'Citas prácticas y flexibles',
    description: 'Las sesiones se adaptan a tus horarios. Reserva las sesiones en el horario que prefieras.',
    imgStart: false,
    img: require('../../images/svg-3.svg'),
    alt: 'Calender'
}

export const infoObjFour = {
    headLine: 'Siempre desde dónde tú estés',
    description: 'Con Nutrihome, todo lo que necesitas está en un solo sitio: una completa plataforma que va donde tú vas.',
    imgStart: true,
    img: require('../../images/svg-4.svg'),
    alt: 'Meeting'
}