import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const HeaderContainer = styled.div`
    border: 1px solid #d8d8d8;
    background-color: #f9f9f9;
    position: relative;
`;

export const HeaderWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const HeaderLinksContainer = styled.div`
    display: flex;
    justify-content: center;   

    @media screen and (max-width: 984px) {
        padding-top: 32px;
    }
`;

export const HeaderLinksWrap = styled.div`
    display: flex;
    justify-content: center;

    @media screen and (max-width: 984px) {
        flex-direction: column;
    }
`;


export const HeaderLink = styled(Link)`
    color: #606c38;
    text-decoration: none;
    font-size: 16px;
    font-weight: 500;
    padding: 24px;
    border-bottom: ${({ isSelected }) => (isSelected ? '3px solid #ad625c' : '3px solid transparent')};
    transition: 0.2s ease-in-out;

    &:hover {
        transition: 0.2s ease-in-out;
        color: #879164;
        border-bottom: 3px solid #ad625c;
    }
`;

export const LinkLabel = styled.span`
    margin-left: 10px;
    margin-right: 5px;
`;

