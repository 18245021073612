import React from 'react';
import { FaAngleDown, FaAngleUp, FaBell, FaComments, FaHome, FaQuestionCircle, FaCog, FaSignOutAlt } from 'react-icons/fa';
import { DataClient } from '../DataClient';
import { Avatar } from './NavbarElements';

export const DataRight = [
    {
        title: 'Citas',
        path: '/dashboard/clients/calendar',
    },
    {
        title: <FaBell size={25}/>,
        path: '/notifications'
    },
    {
        title: <FaComments size={25}/>,
        path: '/messages'
    },
    {
        title: <>
                <Avatar src={DataClient.map(item => item.imgProfile)} alt='avatar' />
                <span>{DataClient.map(item => `${item.firstName} ${item.lastName}`)}</span>
                </>,
        path: '/dashboard/clients',
        iconClosed: <FaAngleDown />,
        iconOpened: <FaAngleUp />,

        subNav: [
            {
                title: 'Panel de control',
                icon: <FaHome />,
                path: '/dashboard/clients'
            },
            {
                title: 'Configuración',
                icon: <FaCog />,
                path: '/configuration'
            },
            {
                title: 'Asistencia',
                icon: <FaQuestionCircle />,
                path: '/support'
            },
            {
                title: 'Cerrar sesión',
                icon: <FaSignOutAlt />,
                path: '/'
            }
        ]
    }
];