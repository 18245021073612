import React, { useState, useEffect } from 'react';
import { DataClient } from '../DataClient';
import ShowMore from '../ShowMoreElement';

import {
    MainContainer,
    MainWrapper,
    MainRow,
    Column1,
    InfoWrapper,
    TitleAside,
    InfoAside,
    Info,
    InfoImg,
    Column2,
    TitleWrapper,
    Title,
    BtnWrapper,
    BtnLink,
    SessionWrapper,
    SessionFilter,
    Filter,
    Line,
    SessionInfo,
    ImgProfessional,
    InfoSession,
    InfoDetail
} from './MainElements'

const MainDashboard = ( {openModal, setSelected} ) => {
    let initialEvents = [];
    DataClient.map((item) => item.events.map(e => {
        initialEvents.push(e);
    }));

    const [status, setStatus] = useState('');
    const [events, setEvents] = useState(initialEvents);

    const handleChange = (e) => {
        setEvents(initialEvents);
        setStatus(e.target.value);
    }

    const filterStatus = () => {
        let filter = [];
        DataClient.map((item) => {
            item.events.map((e) => {
                if(status !== 'Todo') {
                    e.statusEvent === status && filter.push(e);
                    setEvents(filter);
                } 
            })
        });
    }

    useEffect(() => {
        setSelected('main');
    }, [setSelected])


    return (
        <MainContainer>
            <MainWrapper>
                {DataClient.map((item) => {
                    return (
                    <MainRow>
                        <Column1>
                            <InfoWrapper>
                                <TitleAside>Mis datos</TitleAside>
                                <InfoAside>
                                    <Info>Peso actual: {item.weight}kg</Info>
                                    <Info>Altura: {item.height}m</Info>
                                    <Info>IMC: {item.imc}</Info>
                                </InfoAside>
                            </InfoWrapper>
                            <InfoWrapper>
                                <TitleAside>Mis profesionales</TitleAside>
                                <InfoAside>
                                {item.professionals.map(e => {
                                    return (
                                    <InfoImg src={e.imgProfile} />
                                    )
                                })}
                                </InfoAside>
                            </InfoWrapper>
                            <InfoWrapper>
                                <TitleAside>Mi objetivo</TitleAside>
                                <InfoAside>
                                    <Info>{item.goal}</Info>
                                </InfoAside>
                            </InfoWrapper>
                        </Column1>
                        <Column2>
                            <TitleWrapper>
                                <Title>Mis citas</Title>
                                <BtnWrapper>
                                    <BtnLink onClick={() => openModal({name: '', lastName: ''})}>+ Añadir Citas</BtnLink>
                                </BtnWrapper>
                            </TitleWrapper>
                            <SessionWrapper>
                                <SessionFilter>
                                    <Filter onChange={handleChange} onClick={filterStatus}>
                                        <option value='Todo'>Todo</option>
                                        <option value='Completada'>Completada</option>
                                        <option value='Programada'>Programada</option>
                                        <option value='Cancelada'>Cancelada</option>
                                    </Filter>
                                </SessionFilter>
                                <Line />
                                {events.map((e) => {
                                    return (
                                        <SessionInfo> 
                                        <ImgProfessional src={e.imgProfessional} />
                                        <InfoSession>
                                            <InfoDetail id='status'>{e.statusEvent}</InfoDetail>
                                            <InfoDetail id='time'>{e.timeEvent}</InfoDetail>    
                                            <InfoDetail>{e.dateEvent}</InfoDetail>
                                        </InfoSession>
                                        <ShowMore />
                                    </SessionInfo>
                                    )
                                })}
                            </SessionWrapper>
                        </Column2>
                    </MainRow>
                    )
                })}
            </MainWrapper>
        </MainContainer>
    )
}

export default MainDashboard
