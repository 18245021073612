import React, { useState, useEffect } from 'react';
import { FaArrowCircleLeft, FaArrowCircleRight } from 'react-icons/fa';
import styled from 'styled-components';
import { DataClient } from './DataClient'; 
import moment from 'moment';
import 'moment/locale/es';

const CalendarContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f9f9f9;
    color: #606c38;
`;

const CalendarWrapper = styled.div`
    width: 700px;
    border: 1px solid lightgrey;
    border-radius: 4px;
    box-shadow: 2px 2px 2px #eee;
`;

const CalendarHeader = styled.div`
    font-size: 18px;
    font-weight: bold;
    padding: 10px 10px 5px 10px;
    display: flex;
    justify-content: space-between;
    background-color: #bccea5;
`;

const Button = styled.div`
    cursor: pointer;
`;

const CalendarBody = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
`;

const Day = styled.div`
    width: 14.2%;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: ${({ isToday }) => (isToday ? '1px solid #eee' : 'none')};
    background-color: ${({ isSelected }) => (isSelected ? '#eee' : 'none')};
    font-weight: ${({ isFull }) => (isFull ? '900': 'none')};
    color: ${({ isFull }) => (isFull ? '#ad625c': 'none')};
    font-size: ${({ isFull }) => (isFull ? '22px': 'none')};
`;

const CalendarElement = ({ setDate, date }) => {
    const DAYS = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
    const DAYS_LEAP = [31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
    const DAYS_OF_THE_WEEK = ['lun.', 'mar.', 'mié.', 'jue.', 'vie.', 'sáb.', 'dom.'];
    const MONTHS = ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'];
    
    const getStartDayOfMonth = (date) => {
        return new Date(date.getFullYear(), date.getMonth(), 1).getDay();
    }
    
    const isLeapYear = (year) => {
        return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
    }

    const today = new Date();
    const [day, setDay] = useState(date.getDate());
    const [month, setMonth] = useState(date.getMonth());
    const [year, setYear] = useState(date.getFullYear());
    const [startDay, setStartDay] = useState(getStartDayOfMonth(date));

    const days = isLeapYear(date.getFullYear()) ? DAYS_LEAP : DAYS;

    let events = [];
    DataClient.map(item => {
        events = item.events;
    })

    useEffect(() => {
        setDay(date.getDate());
        setMonth(date.getMonth());
        setYear(date.getFullYear());
        setStartDay(getStartDayOfMonth(date));
    }, [date]);

    return (
        <CalendarContainer>
            <CalendarWrapper>
                <CalendarHeader>
                    <Button onClick={() => setDate(new Date(year, month - 1, day))}><FaArrowCircleLeft /></Button>
                    <div>
                    {MONTHS[month]} {year}
                    </div>
                    <Button onClick={() => setDate(new Date(year, month + 1, day))}><FaArrowCircleRight /></Button>
                </CalendarHeader>
                <CalendarBody>
                    {DAYS_OF_THE_WEEK.map(d => (
                    <Day key={d}>
                        <strong>{d}</strong>
                    </Day>
                    ))}
                    {Array(days[month] + (startDay - 1))
                    .fill(null)
                    .map((_, index) => {
                        const d = index - (startDay - 2);
                        let isFull = false;
                        events.forEach(event => {
                            if (event.day === d && event.month-1 === month && event.year === year) {
                                isFull = true;
                            }
                        });
                            return (
                            <Day
                                key={index}
                                isToday={d === today.getDate() && date.getMonth() === today.getMonth() && date.getYear() === today.getYear()}
                                isSelected={d === day}
                                isFull={isFull}
                                onClick={() => setDate(new Date(year, month, d))}
                            >
                                {d > 0 ? d : ''}
                            </Day>
                            );
                    })}
                </CalendarBody>
            </CalendarWrapper>
        </CalendarContainer>
    );
}

export default CalendarElement
