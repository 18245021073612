import React from 'react';
import LogoImg from '../../images/logo.svg';
import { FaBars } from 'react-icons/fa';
import { DataLeft } from './DataLeft';
import { DataRight } from './DataRight';
import SubMenu from './SubMenu';

import {
    Nav,
    LeftContainer,
    RightContainer,
    NavLogo,
    NavImg,
    MobileIcon,
    NavMenu
} from './NavbarElements'

const NavbarDashboard = ({ toggle }) => {
    return (
        <>
            <Nav>
                <LeftContainer>
                    <NavLogo to='/dashboard/clients'>
                        <NavImg src={LogoImg} />
                    </NavLogo>
                    <MobileIcon onClick={toggle}>
                        <FaBars />
                    </MobileIcon>
                    <NavMenu>
                    {DataLeft.map((item, index) => {
                        return <SubMenu item={item} key={index} />;
                    })}
                    </NavMenu>
                </LeftContainer>
                <RightContainer>
                    <NavMenu>
                    {DataRight.map((item, index) => {
                        return <SubMenu item={item} key={index} />;
                    })}
                    </NavMenu>
                </RightContainer>
            </Nav>
        </>
    )
}

export default NavbarDashboard